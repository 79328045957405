import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import translation_en from './translations/en.json';
import translation_es from './translations/es.json';
import translation_ptbr from './translations/pt_BR.json';

const resources = {
  en: { translation: translation_en },
  es: { translation: translation_es },
  pt: { translation: translation_ptbr }
};

const lngDetector = new LanguageDetector();
lngDetector.addDetector({
  name: 'customBrowserDetectionWithFallback',
  lookup(options) {
    // options -> are passed in options

    var lang = navigator.language || navigator.userLanguage;
    var result;

    switch (lang) {
      case 'en':
      case 'en-US':
      case 'en-EG':
      case 'en-AU':
      case 'en-GB':
      case 'en-CA':
      case 'en-NZ':
      case 'en-IE':
      case 'en-ZA':
      case 'en-JM':
      case 'en-BZ':
      case 'en-TT':
        result = 'en';
        break;
      case 'es':
      case 'es-AR':
      case 'es-GT':
      case 'es-CR':
      case 'es-PA':
      case 'es-DO':
      case 'es-MX':
      case 'es-VE':
      case 'es-CO':
      case 'es-PE':
      case 'es-EC':
      case 'es-CL':
      case 'es-UY':
      case 'es-PY':
      case 'es-BO':
      case 'es-SV':
      case 'es-HN':
      case 'es-NI':
      case 'es-PR':
        result = 'es';
        break;
      case 'pt':
      case 'pt-BR':
      case 'pt-PT':
        result = 'pt';
        break;
      default:
        result = 'en';
    }
    return result;
  }
});

const detectorOptions = {
  // order and from where user language should be detected
  order: [
    'querystring',
    'cookie',
    'localStorage',
    'customBrowserDetectionWithFallback'
  ],

  // keys or params to lookup language from
  lookupQuerystring: 'lng',
  lookupCookie: 'i18next',
  lookupLocalStorage: 'i18nextLng',

  // cache user language on
  caches: ['localStorage', 'cookie'],
  excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

  // optional expire and domain for set cookie
  cookieMinutes: 10,
  cookieDomain: 'gymforless.ritualgym.com'
};

i18n
  .use(Backend)
  .use(lngDetector)
  .use(initReactI18next)
  .init({
    detection: detectorOptions,
    resources,
    keySeparator: false,
    interpolation: {
      escapeValue: false
    },
    fallbackLng: 'en-US'
  });

export default i18n;
